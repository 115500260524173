<template>
  <header>
      <h1 class="typo-h1">William Patin</h1>
      <div class="header-sub">
          <h2 class="typo-h2">Communication Design</h2>
          <h3 class="typo-h3">Munich, Germany</h3>
      </div>
  </header>

  <section id="experience">
      <div class="flex items-baseline">
        <h2 class="section-title typo-h2">Experience</h2>
        <span class="-ml-4">(in yrs)</span>
      </div>
      <div class="experience-facts">

        <div class="experience-cont">
          <div clasS="experience-row">
            <div class="experience-years">10<span>+</span></div>
            <div class="experience-field typo-h3">Frontend Development</div>
          </div>
          <div clasS="experience-row skills">
            <div>Websites</div>
            <div>Microsite</div>
            <div>Online stores</div>
            <div>Web animation</div>
            <div>Interactive videos</div>
          </div>
        </div>

        <div class="experience-cont">
          <div clasS="experience-row">
            <div class="experience-years">15<span>+</span></div>
            <div class="experience-field typo-h3">Video Post Production</div>
          </div>
          <div clasS="experience-row skills">
            <div>Motion design</div>
            <div>3D animation</div>
            <div>Visual effects</div>
            <div>Video editing</div>
            <div>Camera operator</div>
          </div>
        </div>

        <div class="experience-cont">
          <div clasS="experience-row">
            <div class="experience-years">20<span>+</span></div>
            <div class="experience-field typo-h3">Audio Post Production</div>
          </div>
          <div clasS="experience-row skills">
            <div>Sound design</div>
            <div>Audio editing</div>
            <div>Audio mixing</div>
          </div>
        </div>

      </div>
  </section>

  <section id="reel">
    <video controls src="./assets/video/williampatin_reel2022.mp4"></video>
  </section>

  <section id="references">
    <h2 class="section-title typo-h2">References</h2>
    <div class="references-brands typo-h3">Audi, BMW, Deutsche Telekom, Generali, Mercedes Benz, Munich Re, SAP, Webedia</div>
  </section>

  <section id="expertise">
    <h2 class="section-title typo-h2">Expertise</h2>

    <div class="expertise">
      <div class="expertise-row-title typo-h3">Video Technologies</div>
      <div class="expertise-row">
        <div class="expertise-facts-title typo-reg">Video</div>
        <div>After Effects, Premiere, Cinema 4D, Blender, Cavalry</div>
      </div>
      <div class="expertise-row">
        <div class="expertise-facts-title typo-reg">Audio</div>
        <div>Logic Pro, Audition, Reaper</div>
      </div>
    </div>

    <div>
      <div class="expertise-row-title typo-h3">Web Technologies</div>
      <div class="expertise-row">
        <div class="expertise-facts-title typo-reg">Coding</div>
        <div>Canvas API, CSS, ExtendScript, GSAP, HTML, JavaScript, MySQL, Node.js, PHP, Python, SVG, Vue.js</div>
      </div>
      <div class="expertise-row">
        <div class="expertise-facts-title typo-reg">Platforms</div>
        <div>Shopware, WooCommerce, Wordpress</div>
      </div>
      <div class="expertise-row">
        <div class="expertise-facts-title typo-reg">Art</div>
        <div>Creative Coding, Generative Art</div>
      </div>
    </div>

  </section>

  <div id="info">

    <section id="personal">
      
      <h2 class="section-title typo-h2">Personal</h2>
      
      <div class="personal-row">
        <div class="personal-facts-title typo-reg">Age</div>
        <div>{{age}}</div>
      </div>
      <div class="personal-row">
        <div class="personal-facts-title typo-reg">Languages</div>
        <div><span>English</span> (fluent), <span>French</span> (fluent), <span>German</span> (fluent), <span>Spanish</span> (Advanced)</div>
      </div>
      <div class="personal-row">
        <div class="personal-facts-title typo-reg">Interests</div>
        <div>Design, typography, generative art, photography, technology</div>
      </div>
      <div class="personal-row">
        <div class="personal-facts-title typo-reg">Sports</div>
        <div>Ex-player of the French American Football National Team and former American Football coach</div>
      </div>

    </section>

    <!-- <section id="contact">

        <h2 class="section-title typo-h2">Contact</h2>
        <div class="contact-row">
          <div class="contact-facts-title typo-reg">Address</div>
          <div>Adalbert-Stifter-Weg 8<br>85570 Markt Schwaben, Germany</div>
        </div>
        <div class="contact-row">
          <div class="contact-facts-title typo-reg">Get in touch</div>
          <div>
            <div class="digital-communication" @click="contact()">  
              <div class="digital-communication-1"></div>
              <div class="digital-communication-2"></div>
              <div class="digital-communication-3"></div>
              <div class="digital-communication-4"></div>
              
              <div class="clipboard" data-tooltip="up 500" aria-label="Copy to clipboard">
                <svg viewBox="0 0 72.73 100"><path class="fill-gray" d="M47.03,24.21c3.31,0,6,2.69,6,6v57.79c0,3.31-2.69,6-6,6H12c-3.31,0-6-2.69-6-6V30.21c0-3.31,2.69-6,6-6H47.03m0-6H12C5.37,18.21,0,23.58,0,30.21v57.79c0,6.63,5.37,12,12,12H47.03c6.63,0,12-5.37,12-12V30.21c0-6.63-5.37-12-12-12h0Z"/><path class="fill-yellow" d="M70.68,19.79L53.03,2.07c-1.32-1.33-3.11-2.07-4.98-2.07H25.7c-6.63,0-12,5.37-12,12v57.79c0,6.63,5.37,12,12,12H60.73c6.63,0,12-5.37,12-12V24.75c0-1.86-.74-3.64-2.05-4.96Zm-18.4,.67V7.74l12.71,12.71h-12.71Z"/></svg>
              </div>
            </div>
          </div>
          <div class="text-sm col-span-2 mt-2">This e-mail address is for contact inquiries only. Please do not send any sensitive information to this address.</div>
        </div>
        
    </section> -->

    <section id="journey">
      <div class="journey-content">
        <h2 class="journey-title typo-h2">Journey</h2>
        <p class="typo-h3">From the connection of exceptional people arise extraordinary things.</p>
        <p>Autodidacticism is what defines me. What first started with a passion for music production and sports was shaped and polished by wonderful colleagues and mentors who never got tired of teaching me everything they knew.</p>
        <p>I have become a sports highlight editor for the German Bundesliga, video editor, producer, ENG-camera operator, director, motion designer, and most importantly, an allround media expert. After co-founding one of Germany's most successful Youtube Original Channels for IDG AG (a program by Google Inc) and leading its production and studio in 2012, my vocation was to go full reset and focus on brand communication and web technologies as a freelancer.</p>
        <p>Nine years later, I've had the pleasure and honour of working on various of Germany's biggest automotive brands and fortune 500 companies TV commercials and communication campaigns. In partnership with Bauhouse and KieferWorx, our small team has grown to become the chosen partner for dozens of Deutsche Telekom's projects, helping them shape their video communication campaigns. Evolving and growing alongside Deutsche Telekom has been a great source of experience and privilege.</p>
        <p>Thank you for your trust, team.</p>
      </div>
    </section>

  </div>

  <div :class="{'success' : personal.copied}" class="modal">Address copied to clipboard</div>

</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data:()=>({
    age: 39,
    personal: {
      written: {
        '1': 'contact',
        '2': 'willpatin',
        '3': 'mozmail.com'
      },
      copied: false
    }
  }),
  mounted(){
    const d = new Date();
    let year = d.getFullYear();
    this.age = year - 1983;
  },
  methods: {
    contact(){
      navigator.clipboard.writeText(`${this.personal.written['1']}@${this.personal.written['2']}.${this.personal.written['3']}`);
      console.log('copied');
      this.personal.copied = true;
      setTimeout(()=>{
        this.personal.copied = false;
      },2000)
    }
  }
}
</script>

<style lang="scss">

@import './scss/fonts.scss';

:root{
  --dark-gray: hsl(210deg 30% 25%);
  --light-gray: hsl(210deg 15% 40%);
  --yellow: #FFCE00;

  --section-margin: clamp(60px,10vh,120px);
}

*{
  box-sizing: border-box;
  word-break: break-word;
  white-space: normal;
}

::selection {
  background: var(--yellow);
}

.typo-reg{
  font-size: 16px
}

.typo-h1{
  font-size: 40px;
}

.typo-h2{
  font-size: 30px;
}

.typo-h3{
  font-size: 20px;
}

@media (min-width: 480px){
  .typo-reg{
    font-size: 20px
  }

  .typo-h1{
    font-size: 80px;
  }

  .typo-h2{
    font-size: 50px;
  }

  .typo-h3{
    font-size: 25px;
  }

}

body{
  font-family: 'IBM Plex Serif', serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;

  background: hsl(0 0% 95%);

  @media (min-width: 480px){
    font-size: 20px;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-gray);
  max-width: 1440px;
  margin: 0 auto;
  padding: 120px 40px 40px;

  @media (min-width: 780px){
    padding: 120px 80px 80px;
  }
}

header{
  margin-bottom: var(--section-margin);

  h1{
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    line-height: 1em;
  }

  .header-sub{
    display: grid;
    grid-template-columns: 1fr;
    align-items: baseline;

    h2{
      font-family: 'IBM Plex Serif';
      font-weight: 500;
      color: var(--yellow);
      line-height: 1em;
    }

    h3{
      font-family: 'IBM Plex Mono';
      font-weight: 500;
      color: var(--yellow);
      line-height: 1em;
      text-align: right;
      position: relative;
      top: 60px;
    }

  }

  @media (max-width: 1100px){
    .header-sub{

      h3{
        display: none;
      }
    }
  }

  @media (min-width: 1100px){
    .header-sub{
      grid-template-columns: 2fr 1fr;

      h3{
        top: 0;
      }

    }
  }
}

section{
  margin-bottom: var(--section-margin);
}

h2.section-title{
  font-family: 'IBM Plex Serif';
  font-weight: 500;

  position: relative;
  display: flex;
  align-items: baseline;
  left: -25px;

  &:before{
    content: '';
    width: 15px;
    height: 15px;
    background-color: var(--yellow);
    margin-right: 10px;
  }

}

// @media (min-width: 960px){
//   h2.section-title{
//     font-size: 50px;
//   }
// }

#experience{

  .experience-facts{
    display: grid;
    grid-template-columns: 1fr;    
    gap: 20px;
  }

  .experience-row{
    margin-bottom: 10px;
  }

  .experience-cont{

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;

    .experience-years{
      font-family: 'IBM Plex Sans Condensed';
      font-weight: 700;
      font-size: 140px;
      line-height: 1em;
      color: transparent;

      display: flex;

      background-image: url(./assets/img/paper_tex.jpg);
      background-clip: text;

      span{
        font-weight: 100;
        font-size: 80px;
        line-height: 1em;
        
        align-self: start;      
        transform: translateY(-10px);

        
        color: var(--dark-gray);
      }
    }

    &:nth-child(1) .experience-years{
      background-position: 10% 20%;
    }
    &:nth-child(2) .experience-years{
      background-position: 80% 50%;
    }
    &:nth-child(3) .experience-years{
      background-position: 50% 30%;
    }

    .experience-field{
      font-family: 'IBM Plex Mono';
      font-weight: 700;
    }


  }

  @media (min-width: 960px){
    .experience-facts{
      grid-template-columns: repeat(3,1fr);
      justify-items: normal;
    }
    .experience-cont:not(:last-child) .experience-row{
      border-right: 1px solid var(--dark-gray);
    }
  }

  @media (min-width: 480px) and (max-width: 960px){
    .experience-cont{
      grid-template-columns: 3fr 2fr;
      gap: 20px;
      align-items: flex-end;
    }

    .experience-cont .experience-row:not(.skills){
      border-right: 1px solid var(--dark-gray);
    }
  }

}


#expertise{

  .expertise{
    margin-top: 10px;
    margin-bottom: 30px;
  }
  
  .expertise-row-title{
    font-family: 'IBM Plex Sans Condensed';
    font-weight: 700;
    text-transform: uppercase;
    color: var(--light-gray);
  
    position: relative;
    display: flex;
    align-items: baseline;
    left: -25px;

    margin-bottom: .5em;
  
    &:before{
      content: '';
      width: 15px;
      height: 3px;
      background-color: var(--yellow);
      margin-right: 10px;
    }

  }
  
  .expertise-facts-title{
    font-family: 'IBM Plex Mono';
    font-weight: 700;
  }
  
  .expertise-row{
    display: grid;
    grid-template-columns: 120px 1fr;
    align-items: baseline;
    margin-bottom: 1em;
  }

  @media (min-width: 480px){
    .expertise-row{
      grid-template-columns: 200px 1fr;
    }
    .expertise-facts-title{
      font-weight: 500;
    }
  }

}

#reel{
  aspect-ratio: 16/9;
  video{
    width: 100%;
    height: 100%;
  }
}


#info{

  display: grid;
  grid-template-columns: 1fr;


  #personal{
    grid-row: 2;
  }
  #contact{
    grid-row: 3;
  }
  #journey{
    grid-row: 1;
  }

  @media (min-width: 1280px){
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
    gap: 0 40px;

    #personal{
      grid-row: 1;
      grid-column: 1;
    }
    #contact{
      grid-row: 2;
      grid-column: 1;
    }
    #journey{
      grid-row: 1/3;
      grid-column: 2;
    }
  }
}

#journey{

  .journey-content{
    max-width: 640px;
    line-height: 1.5em;

    border-left: 2px solid var(--yellow);

    margin-left: -25px;
    padding-left: 25px;

    p{
      margin-bottom: 1em;

      &:first-of-type{
        margin-bottom: .75em;
      }

      &:nth-child(3):first-letter{
        color: var(--yellow); 
        font-weight: 700;
        initial-letter: 2;
        font-size: 300%;
        float: left;
        line-height: 1em;
        margin-right: .15em;
      }
    }

  }

  .journey-title{
    font-family: 'IBM Plex Serif';
    font-weight: 500;
    line-height: 1.5em;
  }

  @media (min-width: 960px) and (max-width: 1280px){
    .journey-content{
      margin: 0 auto;
    }
  }

  @media (min-width: 1280px){
    margin-bottom: 0;

    .journey-content{
      margin-left: 0px;
      padding-left: 40px;
    }
  }

}

#personal{
  .personal-facts-title{
    font-family: 'IBM Plex Mono';
    font-weight: 700;
  }
  
  .personal-row{
    display: grid;
    grid-template-columns: 120px 1fr;
    align-items: baseline;
    margin-bottom: 1em;
  }

  span{
    font-weight: 500;
  }

  @media (min-width: 480px){
    .personal-row{
      grid-template-columns: 200px 1fr;
    }
    .personal-facts-title{
      font-weight: 500;
    }
  }
}

#contact {

  margin-bottom: 40px;

  .contact-row{
    display: block;
    margin-bottom: 1em;
  }

  .contact-facts-title{
    font-family: 'IBM Plex Mono';
    font-weight: 700;
  }

  .digital-communication{
    display: flex;
    font-weight: 500;
    &-1:after{
      content: 'contact';
      display: inline-block;
    }
    &-2:after{
      content: '@';
      display: inline-block;
    }
    &-3:after{
      content: 'willpatin';
      display: inline-block;
    }
    &-4:after{
      content: '.mozmail.com';
      display: inline-block;
    }

    svg{
      height: 1.25em;
      align-self: center;
      cursor: pointer;
    }

    .fill-yellow{
      fill: var(--yellow);
    }
    .fill-gray{
      fill: var(--dark-gray);
    }
  }

  @media (min-width: 960px){
    align-self: flex-end;
    margin-bottom: 0;

    .contact-facts-title{
      font-weight: 700;
    }
    .contact-row{
      display: grid;
      grid-template-columns: 200px 1fr;
      align-items: baseline;
    }
  }

}

.modal{
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  font-size: 18px;

  position: fixed;
  bottom: 20px;
  right: 20px;

  background: var(--yellow);
  padding: 20px;

  opacity: 0;
  transition: opacity .3s ease;

  pointer-events: none;

  &.success{
    opacity: 1;
  }
}

[data-tooltip][aria-label] {
  position: relative;
  margin-left: 10px;
}
[data-tooltip][aria-label]::before {
  content: attr(aria-label);
  font-size: 14px;
  pointer-events: none;
  white-space: pre;
  position: absolute;
  color: #fff;
  background: var(--dark-gray);
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: none;
}
[data-tooltip][aria-label]::after {
  content: "";
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
  border-color: var(--dark-gray);
  border-width: 0;
  border-style: solid;
  opacity: 0;
  visibility: none;
}
[data-tooltip^="up"][aria-label]::before {
  bottom: calc(0.5rem + 100%);
  left: 50%;
  transform: translateX(-50%);
}
[data-tooltip^="up"][aria-label]::after {
  border-top-width: 0.5rem;
  border-right-width: 0.5rem;
  border-right-color: #0000;
  border-left-width: 0.5rem;
  border-left-color: #0000;
  bottom: 100%;
  right: 50%;
  transform: translateX(50%);
}
[data-tooltip][aria-label]:hover::before,
[data-tooltip][aria-label]:hover::after {
  visibility: visible;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}
[data-tooltip$="100"][aria-label]:hover::before,
[data-tooltip$="100"][aria-label]:hover::after {
  transition-delay: 0.1s;
}
[data-tooltip$="500"][aria-label]:hover::before,
[data-tooltip$="500"][aria-label]:hover::after {
  transition-delay: 0.5s;
}
[data-tooltip$="1000"][aria-label]:hover::before,
[data-tooltip$="1000"][aria-label]:hover::after {
  transition-delay: 1s;
}

</style>
